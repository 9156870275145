import { isNil } from "@clipboard-health/util-ts";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { isCapacitorPlatform } from "@src/appV2/lib/utils";
import {
  useDeviceGeoLocationIfAllowed,
  useIsDeviceLocationPermissionGranted,
} from "@src/appV2/Location/deviceLocation/geoLocation";
import { useEffect, useRef } from "react";

import { useRequiresBreakPolicyAcknowledgement } from "./useRequiresBreakPolicyAcknowledgement";

interface UseCheckPreBookingStateParams {
  workplaceId?: string;
}

interface BooleanStateTiming {
  startTime: number;
  endTime: number | undefined;
  duration: number | undefined;
}

function updateStateTiming(timing: BooleanStateTiming, isLoading: boolean): BooleanStateTiming {
  if (!isLoading && isNil(timing.endTime)) {
    const endTime = Date.now();
    return {
      ...timing,
      endTime,
      duration: endTime - timing.startTime,
    };
  }

  return timing;
}

/**
 * Prepares the state for pre-booking checks
 */
export function useCheckPreBookingState(params: UseCheckPreBookingStateParams) {
  const { workplaceId } = params;
  const stateTransitionTimings = useRef<Record<string, BooleanStateTiming>>({
    locationPermission: { startTime: Date.now(), endTime: undefined, duration: undefined },
    geoLocation: { startTime: Date.now(), endTime: undefined, duration: undefined },
    breakPolicy: { startTime: Date.now(), endTime: undefined, duration: undefined },
    totalLoading: { startTime: Date.now(), endTime: undefined, duration: undefined },
  });

  // We need the device's geolocation, otherwise we can't let the user claim the shift
  const {
    data: isDeviceLocationPermissionGranted,
    isLoading: isDeviceLocationPermissionGrantedLoading,
  } = useIsDeviceLocationPermissionGranted();

  const geolocationIsUnavailable = !isDeviceLocationPermissionGranted && isCapacitorPlatform();

  // if the query is disabled, 'isLoading' is true until the query runs and we have data
  // this can cause an infinite spinner if location permissions are not given, as
  // useDeviceGeoLocationIfAllowed will always be isLoading === true. Therefore,
  // we need to use isInitialLoading instead
  // https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries#isinitialloading
  const { data: deviceGeolocation, isInitialLoading: isDeviceGeolocationLoading } =
    useDeviceGeoLocationIfAllowed({
      enabled: !geolocationIsUnavailable,
    });

  const {
    requiresBreakPolicyAcknowledgement,
    breakPolicyNoteId,
    isLoading: isRequiresBreakPolicyAcknowledgementLoading,
  } = useRequiresBreakPolicyAcknowledgement({
    workplaceId,
  });

  const isPreBookingChecksLoading =
    isRequiresBreakPolicyAcknowledgementLoading ||
    isDeviceLocationPermissionGrantedLoading ||
    isDeviceGeolocationLoading;

  useEffect(() => {
    stateTransitionTimings.current.locationPermission = updateStateTiming(
      stateTransitionTimings.current.locationPermission,
      isDeviceLocationPermissionGrantedLoading
    );
  }, [isDeviceLocationPermissionGrantedLoading]);

  useEffect(() => {
    // If geolocation is unavailable, don't measure the time it takes to get the device geolocation
    if (!geolocationIsUnavailable) {
      stateTransitionTimings.current.geoLocation = updateStateTiming(
        stateTransitionTimings.current.geoLocation,
        // If the device geolocation is not defined, it means we're still loading
        isNil(deviceGeolocation)
      );
    }
  }, [deviceGeolocation, geolocationIsUnavailable]);

  useEffect(() => {
    stateTransitionTimings.current.breakPolicy = updateStateTiming(
      stateTransitionTimings.current.breakPolicy,
      isRequiresBreakPolicyAcknowledgementLoading
    );
  }, [isRequiresBreakPolicyAcknowledgementLoading]);

  useEffect(() => {
    // Only want to measure the total loading time when loading stops
    if (!isPreBookingChecksLoading) {
      stateTransitionTimings.current.totalLoading = updateStateTiming(
        stateTransitionTimings.current.totalLoading,
        isPreBookingChecksLoading
      );

      if (
        stateTransitionTimings.current.totalLoading.duration &&
        stateTransitionTimings.current.totalLoading.duration > 1000
      ) {
        logEvent(
          APP_V2_APP_EVENTS.PRE_BOOKING_STATE_CHECKED,
          {
            total: stateTransitionTimings.current.totalLoading.duration,
            transitions: Object.entries(stateTransitionTimings.current).map(([key, value]) => ({
              [key]: value.duration,
            })),
          },
          false
        );
      }
    }
  }, [isPreBookingChecksLoading]);

  return {
    geolocationIsUnavailable,
    deviceGeolocation,
    requiresBreakPolicyAcknowledgement,
    breakPolicyNoteId,
    isPreBookingChecksLoading,
  };
}
