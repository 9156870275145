import { CbhFeatureFlag, FEATURE_FLAG_DEFAULT_VALUES, useCbhFlag } from "@src/appV2/FeatureFlags";
import { differenceInMinutes, parseISO } from "date-fns";

export function useCanCancelShiftWithoutPenalty(shiftStart?: string) {
  const leadTimeConfiguration = useCbhFlag(CbhFeatureFlag.UNDO_BOOKING_LEAD_TIME_CONFIGURATION, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.UNDO_BOOKING_LEAD_TIME_CONFIGURATION],
  });

  const minutesLeftUntilShiftStart = differenceInMinutes(parseISO(shiftStart ?? ""), new Date());
  const hasEnoughTimeLeftUntilLeadTime =
    minutesLeftUntilShiftStart >= leadTimeConfiguration.leadTimeInMinutes;

  const undoTimeThreshold = useCbhFlag(CbhFeatureFlag.UNDO_BOOKING_TIME_THRESHOLD, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.UNDO_BOOKING_TIME_THRESHOLD],
  });
  const undoThresholdSetToPenalize = undoTimeThreshold <= 0;

  return (
    !undoThresholdSetToPenalize && leadTimeConfiguration.enabled && hasEnoughTimeLeftUntilLeadTime
  );
}
