import { BottomSheet, Illustration } from "@clipboard-health/ui-components";
import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { MyShiftCard } from "@src/appV2/redesign/Shift/Card/MyShiftCard";
import { type ModalShiftData } from "@src/appV2/redesign/Shift/types";

interface ShiftBookedBottomSheetProps {
  modalState: UseModalState;
  shift: ModalShiftData;
}

export function ShiftBookedBottomSheet(props: ShiftBookedBottomSheetProps) {
  const { modalState, shift } = props;

  const {
    workplace,
    offer,
    attributes: { timeSlot, start, end, qualification, durationInHours },
  } = shift;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        />
      }
    >
      <DialogContent sx={{ backgroundColor: "unset", py: 8, mt: 8 }}>
        <Stack direction="column" alignItems="center" spacing={9}>
          <Illustration type="shift-booked" />
          <Title variant="h2" component="h2" sx={{ textAlign: "center", width: "14rem" }}>
            Your shift is booked!
          </Title>
          <MyShiftCard
            outlined
            shiftStart={start}
            shiftEnd={end}
            shiftTimeSlot={timeSlot}
            shiftQualificationName={qualification}
            workplaceName={workplace.attributes.name}
            workplaceTimezone={workplace.attributes.location.timezone}
            shiftHourlyPay={offer?.attributes.formattedHourlyPay}
            shiftTotalPay={offer?.attributes.formattedTotalPay}
            shiftWorkDurationInHours={durationInHours}
          />
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
