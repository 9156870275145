import { isDefined } from "@clipboard-health/util-ts";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type GeoLocation } from "@src/appV2/Location";
import { useClaimShift } from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import {
  SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH,
  SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_CONFIRM_BOOKING_MODAL_PATH,
} from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useShiftDiscoverySearchMode } from "@src/appV2/redesign/ShiftDiscovery/useShiftDiscoverySearchMode";
import { useMutation } from "@tanstack/react-query";
import { type generatePath } from "react-router-dom";

import { useCheckPreBookingState } from "./useCheckPreBookingState";

export const PreBookingCheck = {
  LOCATION_PERMISSION: "location-permission",
  BREAK_POLICY_ACKNOWLEDGEMENT: "break-policy-acknowledgement",
  BOOKING_CONFIRMATION: "booking-confirmation",
} as const;

export type PreBookingCheckType = (typeof PreBookingCheck)[keyof typeof PreBookingCheck];

interface AttemptBookingShiftParams {
  geoLocation?: GeoLocation;
  skipChecks?: PreBookingCheckType[];
}

interface UseBookShiftProps {
  shiftId: string;
  shiftOfferId?: string;
  onCallShiftId?: string;
  workplaceId?: string;
  canBookWithoutConfirmation?: boolean;
  navigateToModal: (
    path: string,
    params?: Parameters<typeof generatePath<string>>[1],
    forceReplacePath?: boolean
  ) => void;
  onBook: () => void;
}

interface PerformChecksBeforeBookingParams {
  geolocationIsUnavailable: boolean;
  requiresBreakPolicyAcknowledgement: boolean;
  breakPolicyNoteId?: string;
  navigateToModal: (
    path: string,
    params?: Parameters<typeof generatePath<string>>[1],
    forceReplacePath?: boolean
  ) => void;
  shiftId: string;
  skipChecks: PreBookingCheckType[];
}

/**
 * Performs pre-booking checks and returns whether booking should proceed
 *
 * Sometimes there is a need to skip certain pre-booking checks.
 * For example, we skip the break policy acknowledgement check when the user has just acknowledged the break policy
 * and we want to proceed with the booking. If we don't skip the check, then the state (that the policy has been acknowledged)
 * is not updated yet when we clients call `attemptBookingShift`. This is because the state is updated asynchronously
 * and `requiresBreakPolicyAcknowledgement` which is in the closure might not be updated yet.
 *
 * @returns true if booking should proceed, false otherwise
 */
function performChecksBeforeBooking({
  geolocationIsUnavailable,
  requiresBreakPolicyAcknowledgement,
  breakPolicyNoteId,
  navigateToModal,
  shiftId,
  skipChecks,
}: PerformChecksBeforeBookingParams): boolean {
  if (!skipChecks.includes(PreBookingCheck.LOCATION_PERMISSION) && geolocationIsUnavailable) {
    navigateToModal(SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH);
    return false;
  }

  if (
    !skipChecks.includes(PreBookingCheck.BREAK_POLICY_ACKNOWLEDGEMENT) &&
    requiresBreakPolicyAcknowledgement &&
    isDefined(breakPolicyNoteId)
  ) {
    navigateToModal(SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH, {
      shiftId,
      noteId: breakPolicyNoteId,
    });
    return false;
  }

  // This check ensures that the booking confirmation modal is always displayed at the end of the booking process
  // It is a part of the `useBookShift` which is used throughout the app to ensure consistency in the booking flow
  // The only place that would skip this check is the `ConfirmBookingBottomSheet`
  if (!skipChecks.includes(PreBookingCheck.BOOKING_CONFIRMATION)) {
    navigateToModal(SHIFT_DISCOVERY_SHIFT_CONFIRM_BOOKING_MODAL_PATH, {
      shiftId,
    });
    return false;
  }

  return true;
}

/**
 * This hook is used to book a shift.
 *
 * When performPreBookingChecks is false (default), it assumes that the device
 * already has geolocation and any other acknowledgements or checks have been completed. It is the
 * caller's responsibility to ensure that the shift is in a bookable state before attempting to
 * book the shift.
 *
 * When performPreBookingChecks is true, it will check for location permissions and break policy
 * acknowledgements before attempting to book the shift.
 *
 * @param props takes in the shift to book, and a callback to call once the booking is successful
 * @returns
 */
export function useBookShift(props: UseBookShiftProps) {
  const { onBook, shiftId, shiftOfferId, onCallShiftId, workplaceId, navigateToModal } = props;

  const searchMode = useShiftDiscoverySearchMode();
  const { showErrorToast } = useToast();

  const {
    geolocationIsUnavailable,
    deviceGeolocation,
    requiresBreakPolicyAcknowledgement,
    breakPolicyNoteId,
    isPreBookingChecksLoading,
  } = useCheckPreBookingState({
    workplaceId,
  });

  const { mutateAsync: claimShift } = useClaimShift();

  const { mutateAsync: attemptClaimShift, isLoading: isBooking } = useMutation(
    async (geoLocation?: GeoLocation) => {
      const { latitude, longitude } = geoLocation ?? {};
      if (!shiftOfferId) {
        showErrorToast(
          "Could not load price for booking this shift. Please re-open the app and try again."
        );
        return;
      }

      try {
        await claimShift({
          shiftId,
          offerId: shiftOfferId,
          onCallShiftId,
          searchMode,
          latitude,
          longitude,
        });

        logEvent(APP_V2_USER_EVENTS.INSTANT_BOOKED_SHIFT, { shiftId, onCallShiftId });

        onBook();
      } catch (error: unknown) {
        const errorMessage =
          (error as { response?: { data?: { displayableMessage?: string } } }).response?.data
            ?.displayableMessage ??
          "Something went wrong while booking this shift. Please try again.";
        showErrorToast(errorMessage);
      }
    }
  );

  const attemptBookingShift = async (params?: AttemptBookingShiftParams) => {
    const { geoLocation, skipChecks } = params ?? {};
    const shouldProceed = performChecksBeforeBooking({
      skipChecks: skipChecks ?? [],
      geolocationIsUnavailable,
      requiresBreakPolicyAcknowledgement,
      breakPolicyNoteId,
      navigateToModal,
      shiftId,
    });
    if (!shouldProceed) {
      // If the checks fail, we don't want to attempt to claim the shift
      // The side-effect of the checks are handled in the performChecksBeforeBooking function
      return;
    }

    await attemptClaimShift(geoLocation ?? deviceGeolocation?.geoLocation);
  };

  return {
    attemptBookingShift,
    isBooking,
    isLoading: isPreBookingChecksLoading,
  };
}
